import http from '@/util/http-common'

class ProductDataService {
    getAll (data) {
        return http.post('/products/get_all', data)
    }

    create (data) {
        return http.post('/products', data)
    }

    update (data) {
        return http.patch(`/products/${data.id}`, data)
    }

    delete (id) {
        return http.delete(`/products/${id}`)
    }

    upload (data) {
        return http.post('/products/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    deleteAllProducts () {
        return http.delete('/Products/DeleteAll')
    }

    updateProductZone (data) {
        return http.post('/products/update_zone', data)
    }
}

export default new ProductDataService()
