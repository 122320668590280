import http from '@/util/http-common'

class ZoneDataService {
    getAll () {
        return http.get('/zones')
    }

    create (data) {
        return http.post('/zones', data)
    }

    update (data) {
        return http.patch(`/zones/${data.id}`, data)
    }

    delete (id) {
        return http.delete(`/zones/${id}`)
    }

    getAllWithProducts () {
        return http.get('/zones/products')
    }
}

export default new ZoneDataService()
