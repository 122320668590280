import http from '@/util/http-common'

class OrderDataService {
    getAll(data) {
        return http.post('/orders/get_all', data)
    }
    getAllSecondary(data) {
        return http.post('/orders/get_all_secondary_orders', data)
    }
    create(data) {
        return http.post('/orders', data)
    }

    update(data) {
        return http.patch(`/orders/${data.id}`, data)
    }

    delete(id) {
        return http.delete(`/orders/${id}`)
    }

    upload(data) {
        return http.post('/orders/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    getStatuses() {
        return http.get('/orders/statuses')
    }

    getCsvErrors(data) {
        return http.post('/orders/CsvErrors', data)
    }

    removeCsvErrors() {
        return http.post('/orders/Remove_CsvErrors')
    }

    addBox(data) {
        return http.post('/Orders/AddBoxToOrder', data)
    }
    deleteBox(id) {
        return http.delete('/Orders/DeleteBoxFromOrder', {
            params: {
                Id: id
            }
        })
    }
    addProduct(data) {
        return http.post('/Orders/AddSampleProductToOrderBox', data)
    }

    deleteOrder(id) {
        return http.delete('/Orders/DeleteOrder', {
            params: {
                id: id
            }
        })
    }
    deleteProduct(id) {
        return http.delete('/Orders/DeleteProductFromOrderBox', {
            params: {
                Id: id
            }
        })
    }
    deleteAllOrders() {
        return http.delete('/Orders/DeleteAll');
    }

    orderBoxesSampleProductBulkUpdate(data) {
        return http.post('/Orders/OrderBoxesSampleProductBulkUpdate', data)
    }

    GenerateStatusReport() {
        return http.get('/Orders/status_report')
    }
    // update_status () {
    //     return http.patch('/orders/update_status', data)
    // }
    fixUpload(data) {
        return http.post('/Orders/FixUpload', data)
    }

    downloadCSVError(filename, data) {
        return http.post('/Orders/DownloadCSVError', data, null, {
            responseType: "blob",
            params: {
                FileName: filename
            }
        })
    }
}

export default new OrderDataService()
