import http from '@/util/http-common'

class AnalysisDataService {
    getAll(data) {
        return http.post('/orderAnalysis', data)
    }

    getAllLogs() {
        return http.get('OrderAnalysis/Logs')
    }

    getLast24Hours() {
        return http.get('/orderAnalysis/last24Hours')
    }

    getByFilter(data) {
        return http.post('/orderAnalysis/GetByFilter', data)
    }

    updateStatusByCsvOrders(csv_id, status_id) {
        return http.get('/orderAnalysis/UpdateStatusByCSVOrders', { params: { OrdersCsvHistoryId: csv_id, Status: status_id } })
    }

    SampleProductBulkUpdateForCsv(data) {
        return http.post('/OrderAnalysis/UpdateSampleProductsByCSVOrders', data)
    }
    migrateOrdersToSecondaryTables(createdAt) {
        return http.post('/Setting/MigrateOrdersToSecondaryTables', null, {
            params: {
                Created: createdAt
            }
        })
    }

    migrateOrdersToPrimaryTables(createdAt) {
        return http.post('/Setting/MigrateOrdersToPrimaryTables', null, {
            params: {
                Created: createdAt
            }
        })
    }

    deleteCsv(id) {
        return http.delete('/OrderAnalysis/DeleteCsvOrders', {
            params: {
                OrdersCsvHistoryId: id
            }
        })
    }

    ChangeCsvShiftStatus(id) {
        return http.get('/OrderAnalysis/ChangeCsvShiftStatus', {
            params: {
                ordersCsvHistoryId: id
            }
        })
    }
}

export default new AnalysisDataService()
