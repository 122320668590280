<template>
  <div>
    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Orders Delete</span>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-3">
            <v-radio-group v-model="isDateRange" row>
              <v-radio label="Date" :value="false"></v-radio>
              <v-radio label="Date range" :value="true"></v-radio>
            </v-radio-group>
            <v-row>
              <v-date-picker v-model="date1"></v-date-picker>
              <v-date-picker
                v-model="date2"
                class="ml-2"
                v-if="isDateRange"
              ></v-date-picker>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="blue darken-1" text v-on:click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" text v-on:click="deleteOrders">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogLoading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Loading...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "OrdersDelete",
  props: ["isOpen"],
  data: () => ({
    dialog: false,
    isDateRange: false,
    dialogLoading: false,
    date1: moment().format("YYYY-MM-DD"),
    date2: moment().format("YYYY-MM-DD"),
  }),
  watch: {
    isOpen(val) {
      this.dialog = val;
    },
    dialog(val) {
      if (val == false) {
        this.$emit("close");
      }
    },
  },
  methods: {
    deleteOrders() {
      this.dialogLoading = true;
      if (this.isDateRange) {
        this.$http
          .get("OrderAnalysis/DeleteCsvOrdersBeforeDate", {
            params: {
              from: this.date1,
              to: this.date2,
            },
          })
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
            this.dialogLoading = false;
          })
          .catch((err) => {
            this.dialogLoading = false;
          });
      } else {
        this.$http
          .get("OrderAnalysis/DeleteCsvOrdersBeforeDate", {
            params: {
              date: this.date1,
            },
          })
          .then((response) => {
            if (response.data.success) {
              this.$toast.success(response.data.message, "Success", {
                position: "topRight",
              });
            } else {
              this.$toast.error(response.data.message, "Error", {
                position: "topRight",
              });
            }
            this.dialogLoading = false;
          })
          .catch((err) => {
            this.dialogLoading = false;
          });
      }
      this.dialog = false;
    },
  },
};
</script>