<template>
  <v-container id="data-tables-view" fluid tag="section">
    <loading
      :active.sync="isLoading"
      color="#9de35c"
      loader="bars"
      :width="loadingSize"
      :height="loadingSize"
      :is-full-page="loadingFullPage"
    />

    <material-card
      icon="mdi-clipboard-list"
      icon-small
      color="primary"
      title="Csv Orders"
    >
      <v-card-text>
        <v-row>
          <v-col class="text-left">
            <v-switch v-model="singleExpand" label="Single expand" />
          </v-col>
          <v-col>
            <v-row class="ml-auto" style="max-width: 500px">
              <!-- <v-col>
                <v-autocomplete
                  v-model="fieldName"
                  :items="fields"
                  item-text="text"
                  item-value="value"
                  label="Field Name"
                  single-line
                />
              </v-col> -->
              <v-col>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  hide-details
                  label="Search records"
                  single-line
                  @click:append="searchFunction()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider />
        <v-data-table
          :headers="headers"
          :items="orders"
          :options.sync="options"
          :search.sync="search"
          :server-items-length="totalOrders"
          :loading="loading"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="orderId"
          :show-expand="isExpand"
          :items-per-page="5"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': [5, 10, 15],
          }"
        >
          <template v-slot:top>
            <orders-delete
              :isOpen="dialogDeleteOrder"
              @close="dialogDeleteOrder = false"
            />
            <v-dialog v-model="dialogCsvErrors" persistent max-width="1366px">
              <v-card>
                <v-card-title>
                  <span class="headline">Incorrectly formatted records.</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <!-- <p v-for="(line, index) in csvErrorLines" :key="index">
                      {{ line }}
                    </p> -->
                    <v-data-table
                      :headers="csvErrorHeaders"
                      :items="csvErrorLines.missFields"
                      :footer-props="{
                        showFirstLastPage: true,
                        'items-per-page-options': [5, 10, 15],
                      }"
                    >
                      <template v-slot:body="{ items, headers }">
                        <tbody>
                          <tr v-for="(item, idx) in items" :key="idx">
                            <td v-for="(header, key) in headers" :key="key">
                              <v-edit-dialog
                                :return-value.sync="item[header.value]"
                                large
                                @save="csvSave"
                                @cancel="csvCancel"
                                @open="csvOpen"
                                @close="csvClose"
                              >
                                {{ item[header.value] }}
                                <template v-slot:input>
                                  <v-autocomplete
                                    v-if="
                                      header.value === 'replacementProductId'
                                    "
                                    v-model="item[header.value]"
                                    :items="products"
                                    item-text="productName"
                                    item-value="barcode"
                                    label="Edit"
                                    single-line
                                  />
                                  <v-autocomplete
                                    v-else-if="header.value === 'zoneId'"
                                    v-model="item[header.value]"
                                    :items="zones"
                                    item-text="name"
                                    item-value="id"
                                    label="Bay"
                                    single-line
                                  />
                                  <v-text-field
                                    v-else
                                    v-model="item[header.value]"
                                    label="Edit"
                                    single-line
                                  />
                                </template>
                              </v-edit-dialog>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogCsvErrors = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="updateCsvErrors">
                    Update
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogUpload" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Orders Bulk Upload</span>
                </v-card-title>
                <v-card-text>
                  <v-container class="mt-3">
                    <v-row>
                      <v-file-input
                        v-model="orderCSVFile"
                        label="Orders File (.csv)"
                      />
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogUpload = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="upload">
                    Upload
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSampleProductUpload" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Add Sample Product</span>
                </v-card-title>
                <v-card-text class="py-0 pt-2">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="selectedSampleProducts"
                          :items="sampleProducts"
                          item-text="productName"
                          item-value="barcode"
                          outlined
                          dense
                          chips
                          small-chips
                          label="Sample Product"
                          multiple
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogSampleProductUpload = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addSampleProductToboxes"
                  >
                    Add
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogSampleProductForCsv" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Add Sample Product</span>
                </v-card-title>
                <v-card-text class="py-0 pt-2">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="selectedCsvName"
                          :items="orderAnalysis"
                          item-text="csvName"
                          item-value="id"
                          label="CSV"
                          outlined
                        />
                      </v-col>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="selectedSampleProducts"
                          :items="sampleProducts"
                          item-text="productName"
                          item-value="barcode"
                          outlined
                          chips
                          small-chips
                          label="Sample Product"
                          multiple
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogSampleProductForCsv = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="addSampleProductToboxesForCsv"
                  >
                    Add
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form ref="OrderForm">
                      <v-row>
                        <!-- <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.orderId"
                            label="Order ID"
                            :rules="[rules.required]"
                          />
                        </v-col> -->
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.productId"
                            label="Product ID"
                            :rules="[rules.required]"
                            :disabled="editedIndex > -1"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.zoneId"
                            :items="zones"
                            item-text="name"
                            item-value="id"
                            label="Bay"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field v-model="editedItem.sku" label="SKU" />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.description"
                            label="Description"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.quantity"
                            label="Quantity"
                            :rules="[rules.required, rules.number]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.picked"
                            label="Picked"
                            :rules="[rules.number]"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            v-model="editedItem.statusId"
                            :items="status_list"
                            item-text="statusInText"
                            item-value="id"
                            label="Status"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.instructions"
                            label="Instructions"
                          />
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.replacementProductId"
                            :items="products"
                            item-text="productName"
                            item-value="barcode"
                            label="Replacement Product"
                          />
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to delete this item?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogFtpUpdate" max-width="500px">
              <v-card>
                <v-card-title class="headline">
                  Are you sure you want to update to the SFTP?
                </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="dialogFtpUpdate = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="GenerateStatusReport"
                  >
                    OK
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:[`item.zoneId`]="{ item }">
            <div
              v-if="
                item.zoneId > 0 &&
                zones.find((x) => x.id == item.zoneId) != null
              "
            >
              {{ zones.find((x) => x.id == item.zoneId).name }}
            </div>
            <div v-else>--</div>
          </template>
          <template v-slot:[`item.statusId`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-chip
                  class="ma-2"
                  text-color="white"
                  :v-if="!!item.statusId"
                  :color="
                    !!item.statusId
                      ? status_list.find((x) => x.id == item.statusId).color
                      : ''
                  "
                  v-on="on"
                >
                  {{
                    !!item.statusId
                      ? status_list.find((x) => x.id == item.statusId)
                          .statusInText
                      : ""
                  }}
                </v-chip>
              </template>
              <span>{{
                statusFullForm(!!item.statusId ? item.statusId : 0)
              }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div>{{ dateFormat(item.created) }}</div>
          </template>
          <template v-slot:[`item.orderLines`]="{ item }">
            <div>{{ orderLines(item) }}</div>
          </template>
          <template v-slot:[`item.noOfBoxes`]="{ item }">
            <div>{{ item.boxes.length }}</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              v-if="canEditOrder"
              medium
              color="info"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="canDeleteOrder"
              medium
              color="error"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:[`item.delete`]="{ item }">
            <v-btn
              v-if="canDeleteOrder"
              color="error"
              fab
              small
              @click="deleteOrder(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="primary pa-2">
              <v-data-table
                v-model="selectedBoxes"
                :headers="boxHeaders"
                :items="item.boxes"
                :expanded.sync="boxExpanded"
                item-key="orderBoxId"
                :single-expand="singleExpand"
                show-expand
                show-select
                hide-default-footer
              >
                <template v-slot:[`header.add`]>
                  <div>Actions</div>
                  <!-- <v-btn
                    @click="openDialogBox(item.orderId)"
                    v-if="canCreateOrder"
                    color="accent"
                    fab
                    small
                  >
                    <v-icon>mdi-view-grid-plus</v-icon>
                  </v-btn> -->
                </template>
                <template v-slot:top>
                  <v-dialog v-model="dialogBox" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <span class="headline">Add New Box</span>
                      </v-card-title>
                      <v-card-text>
                        <v-row class="mx-2">
                          <v-col md="10">
                            <v-text-field
                              v-model="currentOrderBoxId"
                              label="Box Number"
                            />
                          </v-col>
                          <v-col md="2" class="">
                            <v-btn
                              v-if="canCreateOrder"
                              color="primary"
                              @click="addBox"
                            >
                              Add
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:[`item.add`]="{ item }">
                  <!-- <v-btn
                    @click="openDialogBox(item.orderId)"
                    v-if="canCreateOrder"
                    class="mr-1"
                    color="accent"
                    fab
                    small
                  >
                    <v-icon>mdi-view-grid-plus</v-icon>
                  </v-btn> -->
                  <v-btn
                    v-if="canDeleteOrder"
                    color="error"
                    fab
                    small
                    @click="deleteBox(item)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:[`item.products`]="{ item }">
                  <!-- <v-btn
                    @click="deleteBox(item)"
                    v-if="canDeleteOrder"
                    color="primary"
                    fab
                    small
                  >
                    {{ item.products.length }}
                  </v-btn> -->
                  <div>{{ item.products.length }}</div>
                </template>
                <template v-slot:[`item.sampleProducts`]="{ item }">
                  <!-- <v-btn
                    @click="deleteBox(item)"
                    v-if="canDeleteOrder"
                    color="primary"
                    fab
                    small
                  >
                    {{ item.sampleProducts.length }}
                  </v-btn> -->
                  <div>{{ item.sampleProducts.length }}</div>
                </template>
                <template v-slot:[`item.statusId`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-chip
                        class="ma-2"
                        text-color="white"
                        :v-if="!!item.statusId"
                        :color="
                          !!item.statusId
                            ? status_list.find((x) => x.id == item.statusId)
                                .color
                            : ''
                        "
                        v-on="on"
                      >
                        {{
                          !!item.statusId
                            ? status_list.find((x) => x.id == item.statusId)
                                .statusInText
                            : ""
                        }}
                      </v-chip>
                    </template>
                    <span>{{
                      statusFullForm(!!item.statusId ? item.statusId : 0)
                    }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="accent pa-2">
                    <OrderLinesTable
                      :items="item.products"
                      :box-id="item.orderBoxId"
                      :zones="zones"
                      :status_list="status_list"
                      @editItem="editItem"
                      @deleteItem="deleteItem"
                      @addSampleProduct="addSampleProduct"
                    />
                  </td>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card-text>
    </material-card>
  </v-container>
</template>
<script>
import OrdersDelete from "./components/OrdersDelete.vue";
import ProductDataService from "@/services/ProductDataService";
import OrderDataService from "@/services/OrderDataService";
import ZoneDataService from "@/services/ZoneDataService";
import SampleProductDataService from "@/services/SampleProductDataService";
import AnalysisDataService from "../services/AnalysisDataService";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import OrderLinesTable from "./components/OrdersLinesTable.vue";
import moment from "moment";

export default {
  name: "Orders",
  components: { Loading, OrderLinesTable, OrdersDelete },
  data: () => ({
    permissions: localStorage.getItem("permissions"),
    dialogFtpUpdate: false,
    productTable: false,
    sampleProductTable: false,
    loading: false,
    isLoading: false,
    loadingSize: 128,
    loadingFullPage: false,
    rules: {
      required: (value) => !!value || "Required.",
      number: (value) =>
        Number.isInteger(Number(value)) || "The value must be an number.",
    },
    isSearch: false,
    expanded: [],
    boxExpanded: [],
    isExpand: false,
    singleExpand: true,
    dialog: false,
    dialogDelete: false,
    dialogUpload: false,
    dialogCsvErrors: false,
    dialogBox: false,
    dialogSampleProductUpload: false,
    dialogSampleProductForCsv: false,
    dialogDeleteOrder: false,
    headers: [],
    boxHeaders: [],
    totalOrders: 0,
    options: {},
    search: "",
    awaitingSearch: false,
    triggerSearch: false,
    orders: [],
    currentType: "",
    currentOrderId: "",
    currentOrderBoxId: "",
    zones: [],
    csvErrorLines: [],
    status_list: [],
    orderAnalysis: [],
    sampleProducts: [],
    products: [],
    selectedCsvName: "",
    selectedSampleProducts: [],
    selectedBoxes: [],
    orderCSVFile: [],
    editedIndex: -1,
    editedItem: {
      orderId: "",
      productId: "",
      zoneId: "",
      sku: "",
      description: "",
      quantity: "",
      picked: 0,
      statusId: 1,
      pickingDateTime: "",
      replacementProductId: "",
      instructions: "",
    },
    defaultItem: {
      orderId: "",
      productId: "",
      zoneId: "",
      sku: "",
      description: "",
      quantity: "",
      picked: 0,
      statusId: 1,
      pickingDateTime: "",
      replacementProductId: "",
      instructions: "",
    },
    fieldName: "OrderId",
    fields: [
      {
        text: "Invoice Number",
        value: "OrderId",
      },
      {
        text: "Box Number",
        value: "OrderBoxId",
      },
      {
        text: "Product ID",
        value: "ProductId",
      },
      {
        text: "SKU",
        value: "SKU",
      },
      {
        text: "Order Status",
        value: "OrderStatusId",
      },
      {
        text: "Box Status",
        value: "OrderBoxStatusId",
      },
      {
        text: "Order Line Status",
        value: "OrderProductStatusId",
      },
      {
        text: "Status",
        value: "StatusId",
      },
    ],
    csvErrorHeaders: [
      {
        text: "InvoiceNumber",
        sortable: false,
        value: "orderId",
      },
      {
        text: "BoxId",
        sortable: false,
        value: "orderBoxId",
      },
      {
        text: "ProductId",
        sortable: false,
        value: "productId",
      },
      {
        text: "SKU",
        sortable: false,
        value: "sku",
      },
      {
        text: "Description",
        sortable: false,
        value: "description",
      },
      {
        text: "Zone",
        sortable: false,
        value: "zoneId",
      },
      {
        text: "Quantity",
        sortable: false,
        value: "quantity",
      },
      {
        text: "OutOfStock",
        sortable: false,
        value: "outOfStock",
      },
      {
        text: "ReplacementProduct",
        sortable: false,
        value: "replacementProductId",
      },
      {
        text: "CustomerTag",
        sortable: false,
        value: "customerTag",
      },
      // {
      //   text: "Actions",
      //   sortable: false,
      //   value: "actions",
      // },
    ],
    searchTimerId: null,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New Order" : "Edit Order";
    },
    boxFormTitle() {
      return this.editedIndex === -1 ? "Add New Order" : "Edit Order";
    },
    canCreateOrder() {
      return this.permissions.includes("Permissions.Orders.Create");
    },
    canSampleProductsMultiCreate() {
      return this.permissions.includes(
        "Permissions.Orders.SampleProducts.MultiCreate"
      );
    },
    canSampleGenerateStatusReport() {
      return this.permissions.includes(
        " Permissions.Orders.Products.GenerateStatusReport"
      );
    },
    canSampleProductsByCsv() {
      return this.permissions.includes(
        "Permissions.OrderAnalysis.UpdateSampleProductsByCSVOrders"
      );
    },
    canBulkUploadOrders() {
      return this.permissions.includes("Permissions.Orders.Upload");
    },
    canViewCsvErrors() {
      return this.permissions.includes("Permissions.CsvErrors.ViewAll");
    },
    canEditOrder() {
      return this.permissions.includes("Permissions.Orders.Edit");
    },
    canDeleteOrder() {
      return this.permissions.includes("Permissions.Orders.Delete");
    },
    csvId() {
      return this.$route.query ? this.$route.query.csv : 0;
    },
    zoneId() {
      return this.$route.query ? this.$route.query.zone : 0;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        if (this.options.page !== 1) {
          this.getDataFromApi(this.search);
        }
      },
      deep: true,
    },
  },

  created() {
    OrderDataService.getStatuses().then((response) => {
      this.status_list = response.data;
    });

    ZoneDataService.getAll().then((response) => {
      this.zones = response.data;
    });

    var data2 = {
      Start: 0,
      Length: -1,
      Search: "",
      SortColumnName: "id",
      SortDirection: "desc",
    };
    ProductDataService.getAll(data2).then((response) => {
      this.products = response.data.products;
    });

    var data = {
      Start: 0,
      Length: 10,
      Search: "",
      SortColumnName: "",
      SortDirection: "",
    };
    AnalysisDataService.getAll(data).then((response) => {
      this.orderAnalysis = response.data.uploadedFiles;
    });

    var data1 = {
      Start: 0,
      Length: -1,
      Search: "",
      SortColumnName: "id",
      SortDirection: "desc",
    };
    SampleProductDataService.getAll(data1).then((response) => {
      this.sampleProducts = response.data.sampleProducts;
    });
  },
  mounted() {
    this.getDataFromApi(this.search);
  },
  beforeDestroy() {},
  methods: {
    dateFormat(date) {
      if (date) {
        return moment.utc(date).local().format("h:mm:ss a DD.MM.YYYY");
      } else {
        return "";
      }
    },
    orderLines(order) {
      var orderlines = 0;
      order.boxes.forEach((x) => {
        orderlines = orderlines + x.products.length;
      });

      return orderlines;
    },

    getDataFromApi(search) {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      var data = {
        Start: itemsPerPage * (page - 1),
        Length: itemsPerPage,
        Search: search,
        SortColumnName: "Created",
        SortDirection: "desc",
        csvId: this.csvId,
        zoneId: this.zoneId,
      };

      if (search == null || search == "") {
        data.SortColumnName = "Created";
      } else {
        data.SortColumnName = "Id";
      }

      this.$http
        .post("/Orders/GetOrderProductsForCsvByZoneQuery", data)
        .then((response) => {
          if (response.data.success) {
            this.isSearch = response.data.data.search;
            if (
              response.data.data.orders != null &&
              response.data.data.orders.length > 0
            ) {
              this.orders = response.data.data.orders;
            } else {
              this.orders = [];
            }
            this.totalOrders = response.data.data.totalOrders;
            this.loading = false;

            if (this.isSearch) {
              this.isExpand = false;
              this.headers = [
                {
                  text: "Invoice Number",
                  value: "orderBox.order.orderId",
                  sortable: false,
                },
                {
                  text: "Box Number",
                  value: "orderBox.orderBoxId",
                  sortable: false,
                },
                { text: "Product ID", value: "productId", sortable: false },
                { text: "Bay", value: "zoneId", sortable: false },
                { text: "SKU", value: "sku", sortable: false },
                { text: "Description", value: "description", sortable: false },
                {
                  text: "Quantity",
                  align: "center",
                  value: "quantity",
                  sortable: false,
                },
                {
                  text: "Picked",
                  align: "center",
                  value: "picked",
                  sortable: false,
                },
                {
                  text: "Status",
                  align: "center",
                  value: "statusId",
                  sortable: false,
                },
                {
                  text: "Replacement Product",
                  value: "replacementProductId",
                  sortable: false,
                },
                {
                  text: "Instruction",
                  align: "center",
                  value: "instructions",
                  sortable: false,
                },
                { text: "Created", value: "created", sortable: false },
              ];

              if (this.canEditOrder || this.canDeleteOrder) {
                this.headers.push({
                  text: "Actions",
                  value: "actions",
                  sortable: false,
                });
              }
            } else {
              this.isExpand = true;
              this.headers = [
                {
                  text: "Invoice Number",
                  value: "orderId",
                  sortable: false,
                },
                {
                  text: "Status",
                  align: "center",
                  value: "statusId",
                  sortable: false,
                },
                { text: "Created", value: "created", sortable: false },
                {
                  text: "No of boxes",
                  align: "center",
                  value: "noOfBoxes",
                  sortable: false,
                },
                {
                  text: "Invoice lines",
                  align: "center",
                  value: "orderLines",
                  sortable: false,
                },
                {
                  text: "Actions",
                  align: "end",
                  value: "delete",
                  sortable: false,
                },
              ];
              this.boxHeaders = [
                {
                  text: "Box Number",
                  sortable: false,
                  value: "orderBoxId",
                },
                {
                  text: "Status",
                  align: "center",
                  value: "statusId",
                  sortable: false,
                },
                {
                  text: "Products",
                  align: "center",
                  sortable: false,
                  value: "products",
                },
                {
                  text: "Sample Products",
                  align: "center",
                  sortable: false,
                  value: "sampleProducts",
                },
              ];
              if (this.canEditOrder || this.canDeleteOrder) {
                this.boxHeaders.push({
                  text: "Add",
                  align: "end",
                  value: "add",
                  sortable: false,
                });
              }
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          this.orders = [];
        });
    },
    addSampleProduct(item) {
      OrderDataService.addProduct(item).then((response) => {
        // this.orders.push(this.editedIndex);
        this.getDataFromApi(this.search);
        this.$toast.success("Order added successfully.", "Success", {
          position: "topRight",
        });
      });
    },
    editItem(item) {
      if (this.isSearch) {
        this.editedIndex = 0;
      } else {
        this.editedIndex = 0;
      }
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      // when searching item doesn't have parent item
      // when grouped format editedIndex is parentItem index
      if (this.isSearch) {
        this.editedIndex = this.orders.indexOf(item);
      } else {
        this.editedIndex = this.orders.indexOf(item);
      }
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
      this.currentType = "product";
    },

    deleteItemConfirm() {
      switch (this.currentType) {
        case "order":
          OrderDataService.deleteOrder(this.currentOrderId).then((response) => {
            this.getDataFromApi(this.search);
            this.$toast.success("Order deleted successfully.", "Success", {
              position: "topRight",
            });
          });
          this.closeDelete();
          break;
        case "box":
          OrderDataService.deleteBox(this.currentOrderBoxId).then(
            (response) => {
              this.getDataFromApi(this.search);
              this.$toast.success("Box deleted successfully.", "Success", {
                position: "topRight",
              });
            }
          );
          this.closeDelete();
          break;
        case "product":
          OrderDataService.deleteProduct(this.editedItem.id).then(
            (response) => {
              this.getDataFromApi(this.search);
              this.$toast.success("Product deleted successfully.", "Success", {
                position: "topRight",
              });
            }
          );
          this.closeDelete();

          if (this.search) {
            this.orders.splice(this.editedIndex, 1);
          } else {
            this.orders[this.editedIndex].products.splice(this.editItem.id, 1);
          }
          break;
        default:
          this.closeDelete();
          break;
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.$refs.OrderForm.validate()) {
        this.orders.forEach((x) => {
          x.boxes.forEach((y) => {
            if (y.orderBoxId == this.editedItem.orderId) {
              this.editedItem.orderId = x.orderId;
            }
          });
        });

        OrderDataService.update(this.editedItem).then((response) => {
          this.getDataFromApi(this.search);
          this.$toast.success("Order product edited successfully.", "Success", {
            position: "topRight",
          });
        });
      }
      this.close();
    },

    upload() {
      if (this.orderCSVFile) {
        this.isLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("FormFile", this.orderCSVFile);
        OrderDataService.upload(bodyFormData)
          .then((response) => {
            this.getDataFromApi(this.search);
            this.isLoading = false;
            if (response.data.isSuccess && !response.data.isErrorCsvLines) {
              this.$toast.success("Orders imported successfully.", "Success", {
                position: "topRight",
              });
            } else {
              if (response.data.isOldOrders) {
                this.$toast.error(
                  "One or many orders/orderlines already exists. Duplicate records",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              } else if (response.data.isErrorCsvLines) {
                this.downloadCsvError(
                  response.data.fileName,
                  response.data.csvLines
                );
                this.$toast.error(
                  "Some record/s in the .csv file are incorrectly formatted.",
                  "Error",
                  {
                    position: "topRight",
                  }
                );
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast.error(
              "Something went wrong. please try again later",
              "Error",
              {
                position: "topRight",
              }
            );
          });
      } else {
        this.$toast.error("No file selected.", "Error", {
          position: "topRight",
        });
      }
      this.orderCSVFile = [];
      this.dialogUpload = false;
    },

    groupStatus(products) {
      // statusId = 1 = WAIT, 2= PPKD, 3=PIKD, 4=PRCS
      if (products.some((x) => x.statusId == 1)) {
        // if any of a product statusID = 1 that means wait or partially picked
        if (products.some((x) => x.statusId > 1)) {
          // partially picked
          return 2;
        } else {
          // wait
          return 1;
        }
      } else if (products.some((x) => x.statusId == 2)) {
        // partially picked
        return 2;
      } else if (products.some((x) => x.statusId == 3)) {
        // if any of a product statusID = 3 that means partially picked or picked
        if (products.some((x) => x.statusId < 3)) {
          // partially picked
          return 2;
        } else {
          // picked
          return 3;
        }
      } else {
        for (let i = 1; i <= 7; i++) {
          if (products.some((x) => x.statusId == i)) {
            return i;
            break;
          }
        }
      }
    },
    statusFullForm(id) {
      var status = "";
      switch (id) {
        case 1:
          status = "Waiting to be picked";
          break;
        case 2:
          status = "Partially picked";
          break;
        case 3:
          status = "Order/line picked";
          break;
        case 4:
          status = "Order passed Final Station";
          break;
        case 5:
          status = "Order completed at the warehouse";
          break;
        case 6:
          status = "Order dispatched from the warehouse";
          break;
        case 7:
          status = "Order delivered to the customer";
          break;
      }
      return status;
    },
    openDialogBox(id) {
      this.dialogBox = true;
      this.currentOrderId = id;
    },
    addBox() {
      var data = {
        orderBoxId: this.currentOrderBoxId,
        orderId: this.currentOrderId,
      };
      OrderDataService.addBox(data).then((response) => {
        // this.orders.push(this.editedIndex);
        this.getDataFromApi(this.search);
        this.$toast.success("Box added successfully.", "Success", {
          position: "topRight",
        });
      });
      this.dialogBox = false;
    },
    deleteOrder(item) {
      this.currentOrderId = item.orderId;
      this.currentType = "order";
      this.dialogDelete = true;
    },
    deleteBox(item) {
      this.currentOrderBoxId = item.orderBoxId;
      this.currentType = "box";
      this.dialogDelete = true;
    },
    addSampleProductDialog() {
      if (this.selectedBoxes.length > 0) {
        this.dialogSampleProductUpload = true;
      } else {
        this.$toast.error("There are no boxes selected.", "Error", {
          position: "topRight",
        });
      }
    },
    addSampleProductToboxes() {
      var boxes = [];
      var sampleProducts = [];

      this.selectedBoxes.forEach((x) => {
        boxes.push({ orderBoxId: x.orderBoxId });
      });

      this.selectedSampleProducts.forEach((x) => {
        sampleProducts.push({ sampleProductBarcode: x });
      });

      var data = {
        sampleProducts: sampleProducts,
        boxes: boxes,
      };
      OrderDataService.orderBoxesSampleProductBulkUpdate(data).then(
        (response) => {
          // this.orders.push(this.editedIndex);
          if (response.data.isSuccess) {
            this.getDataFromApi(this.search);
            this.$toast.success(
              "Sample products added successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          }
        }
      );
      this.selectedBoxes = [];
      this.selectedSampleProducts = [];
      this.dialogSampleProductUpload = false;
    },
    addSampleProductToboxesForCsv() {
      var sampleProducts = [];

      this.selectedSampleProducts.forEach((x) => {
        sampleProducts.push({ sampleProductBarcode: x });
      });

      var data = {
        OrdersCsvHistoryId: this.selectedCsvName,
        SampleProducts: sampleProducts,
      };
      AnalysisDataService.SampleProductBulkUpdateForCsv(data).then(
        (response) => {
          if (response.status == 200) {
            this.getDataFromApi(this.search);
            this.$toast.success(
              "Sample products added successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          }
        }
      );
      this.dialogSampleProductForCsv = false;
      this.selectedCsvName = "";
      this.selectedSampleProducts = [];
    },
    GenerateStatusReport() {
      OrderDataService.GenerateStatusReport()
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success(
              "Report updated to FTP successfully.",
              "Success",
              {
                position: "topRight",
              }
            );
          } else {
            this.$toast.error(response.data, "Error", {
              position: "topRight",
            });
          }
        })
        .catch((error) => {
          this.$toast.error(error.response.data, "Error", {
            position: "topRight",
          });
        });

      this.dialogFtpUpdate = false;
    },
    csvSave() {},
    csvCancel() {},
    csvOpen() {},
    csvClose() {},
    deleteCsvError(item) {},
    updateCsvErrors() {
      let isAllFieldsFilled = true;
      this.csvErrorLines.missFields.forEach((x) => {
        if (
          !x.zoneId ||
          !x.orderId ||
          !x.orderBoxId ||
          !x.productId ||
          !x.sku ||
          !x.description ||
          !x.quantity
        ) {
          isAllFieldsFilled = false;
        }
      });
      if (isAllFieldsFilled) {
        OrderDataService.fixUpload(this.csvErrorLines).then((response) => {
          if (response.status == 200) {
            this.$toast.success("Csv errors updated successfully.", "Success", {
              position: "topRight",
            });
          }
          this.dialogCsvErrors = false;
        });
      } else {
        this.$toast.error("Fill all required fields.", "Error", {
          position: "topRight",
        });
      }
    },
    downloadCsvError(filename, csvErrorLines) {
      OrderDataService.downloadCSVError(filename, csvErrorLines)
        .then((response) => {
          var fileUrl = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute(
            "download",
            "myOrders_CSV_Errors_" +
              this.$store.state.authUser.username +
              "_" +
              this.moment().format("h:mm:ss a DD.MM.YYYY") +
              "_" +
              filename
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {});
    },
    searchFunction() {
      this.options.page = 1;
      this.getDataFromApi(this.search);
    },
  },
};
</script>
