import http from '@/util/http-common'

class SampleProductDataService {
    getAll (data) {
        return http.post('/SampleProduct/get_all', data)
    }

    create (data) {
        return http.post('/SampleProduct', data)
    }

    update (data) {
        return http.patch(`/SampleProduct/${data.id}`, data)
    }

    delete (id) {
        return http.delete(`/SampleProduct/${id}`)
    }

    upload (data) {
        return http.post('SampleProduct/upload', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    // updateProductZone (data) {
    //     return http.post('/products/update_zone', data)
    // }
}

export default new SampleProductDataService()
